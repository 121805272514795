<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Religion</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
            <DataTable :value="religionList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" paginator :totalRecords="totalRecords" :rows="30" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" >
            <template #empty>No Records found.</template>
            <template #loading>Loading data. Please wait...</template>
            <Column field="id" header="Sr." headerStyle="width: 5%" bodyStyle="width: 5%">
                <template #body="{ data }">
                    {{ data.id + 1 }}
                </template>
            </Column>
            <Column field="Name" header="Religion Name" headerStyle="width: 60%" bodyStyle="width: 60%">
                <template #body="{ data }">
                    <div>{{ data.label ? data.label : "N/A" }}</div>
                </template>
            </Column>
            <Column field="count" header="Counts (2011)" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>{{ data.count ? data.count : "N/A" }}</div>
                </template>
            </Column>
            <Column field="percentage" header="Percentage" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div>{{ data.percentage ? data.percentage : "N/A" }}<span v-if="data.percentage != ''">%</span></div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>

export default {
    data() {
        return {
            religionList: [
                {
                    id: 0,
                    label: "Hinduism",
                    count: "10,03,057",
                    percentage: "66.08",
                },
                {
                    id: 1,
                    label: "Islam",
                    count: "1,27,152",
                    percentage: "8.33",
                },
                {
                    id: 2,
                    label: "Christianity",
                    count: "3,80,073",
                    percentage: "25.01",
                },
                {
                    id: 3,
                    label: "Sikhism",
                    count: "63,247",
                    percentage: "0.10",
                },
                {
                    id: 4,
                    label: "Buddhism",
                    count: "45,200",
                    percentage: "0.08",
                },
                {
                    id: 5,
                    label: "Jainism",
                    count: "44,349",
                    percentage: "0.08",
                },
                {
                    id: 6,
                    label: "Other",
                    count: "22,965",
                    percentage: "0.02",
                },
                {
                    id: 7,
                    label: "Not stated",
                    count: "86,290",
                    percentage: "0.21",
                },
            ],
            totalRecords: 8,
        };
    },
};
</script>